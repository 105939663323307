// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-docs-cpp-clion-plugin-md": () => import("./../../../src/docs/cpp/clion-plugin.md" /* webpackChunkName: "component---src-docs-cpp-clion-plugin-md" */),
  "component---src-docs-cpp-command-line-interface-md": () => import("./../../../src/docs/cpp/command-line-interface.md" /* webpackChunkName: "component---src-docs-cpp-command-line-interface-md" */),
  "component---src-docs-cpp-home-md": () => import("./../../../src/docs/cpp/Home.md" /* webpackChunkName: "component---src-docs-cpp-home-md" */),
  "component---src-docs-cpp-stubs-md": () => import("./../../../src/docs/cpp/stubs.md" /* webpackChunkName: "component---src-docs-cpp-stubs-md" */),
  "component---src-docs-cpp-vscode-plugin-md": () => import("./../../../src/docs/cpp/vscode-plugin.md" /* webpackChunkName: "component---src-docs-cpp-vscode-plugin-md" */),
  "component---src-docs-java-check-system-requirements-md": () => import("./../../../src/docs/java/Check-system-requirements.md" /* webpackChunkName: "component---src-docs-java-check-system-requirements-md" */),
  "component---src-docs-java-fine-tune-test-generation-md": () => import("./../../../src/docs/java/Fine-tune-test-generation.md" /* webpackChunkName: "component---src-docs-java-fine-tune-test-generation-md" */),
  "component---src-docs-java-generate-tests-with-default-configuration-md": () => import("./../../../src/docs/java/Generate-tests-with-default-configuration.md" /* webpackChunkName: "component---src-docs-java-generate-tests-with-default-configuration-md" */),
  "component---src-docs-java-get-use-of-test-results-md": () => import("./../../../src/docs/java/Get-use-of-test-results.md" /* webpackChunkName: "component---src-docs-java-get-use-of-test-results-md" */),
  "component---src-docs-java-install-or-update-plugin-md": () => import("./../../../src/docs/java/Install-or-update-plugin.md" /* webpackChunkName: "component---src-docs-java-install-or-update-plugin-md" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cpp-jsx": () => import("./../../../src/pages/cpp.jsx" /* webpackChunkName: "component---src-pages-cpp-jsx" */),
  "component---src-pages-go-jsx": () => import("./../../../src/pages/go.jsx" /* webpackChunkName: "component---src-pages-go-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-js-jsx": () => import("./../../../src/pages/js.jsx" /* webpackChunkName: "component---src-pages-js-jsx" */),
  "component---src-pages-python-jsx": () => import("./../../../src/pages/python.jsx" /* webpackChunkName: "component---src-pages-python-jsx" */),
  "component---src-pages-research-jsx": () => import("./../../../src/pages/research.jsx" /* webpackChunkName: "component---src-pages-research-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-utbot-jsx": () => import("./../../../src/pages/utbot.jsx" /* webpackChunkName: "component---src-pages-utbot-jsx" */)
}

